@import "../common";

.homepage {
    a {
        font-weight: 600;
    }

    .hero-section-home {
        display: table;
        height: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .hero-section-home .align-bottom {
        display: table-cell;
        vertical-align: bottom;
    }


    .hero-section-home {
        p {
            font-weight: 500;
            margin-bottom: 0px;
        }
        h1 {
            font-size: 40px;
            font-weight: 600;
            color: #000;
        }
    }

    .planning-design-section {
        padding-left: 30px;
        @include respond-to-min($desktopSize) {
            margin-top: 0;
        }
        h2 {
            font-size: 32px;
            font-weight: 600;
            color: #000;
            line-height: 36px;
        }
        p {
            font-weight: 500;
        }
    }

    .blog-title-new {
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .margin-top-bot-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .planning-design-section .green {
        color: #8dc126;
    }

    .planning-design-section .green strong {
        font-weight: 600;
    }



    .pland-more-green {
        background-color: #8dc126;
        margin-bottom: 100px;
        margin-top: 100px;
    }

    .right-hand-side-green {
        padding-top: 100px;
        padding-bottom: 140px;
        padding-left: 50px;
    }

    .right-hand-side-green h2 {
        font-weight: 600;
        font-size: 24px;
    }

    .image-absolute-pland img {
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: 0%;
        z-index: 1;
    }

    .image-absolute-pland {
        position: relative;
        top: 0px;
        height: 100%;
    }


    .slider-home-full {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 500px;
        width: 100%;
    }


    .news {
        @include respond-to-min($desktopSize) {
            margin-bottom: $gutter * 4;
        }

        @include news-item;
    }

    .owl-carousel {
        .owl-nav {
            //@include respond-to-max($desktopSize) {
            //    display: none;
            //}

            //@include respond-to-min($desktopSize) {
                position: absolute;
                top: 18%;
                width: 100%;
                z-index: 0;
                //height: 100%;
                button {
                    position: absolute;
                    display: block;

                    &:hover {
                        background: transparent;
                    }
                    img {
                        height: 1.5rem;
                        @include respond-to-min($tabletSize) {
                            height: 2rem;
                        }
                        @include respond-to-min($desktopSize) {
                            height: 3.2rem;
                        }
                    }

                    &:nth-child(1) {
                        transform: rotate(90deg) translateY(250%);
                        left: 32px;
                        @include respond-to-min($tabletSize) {
                            left: 25px;
                        }
                        @include respond-to-min($desktopSize) {
                            left: 0;
                        }
                    }
                    &:nth-child(2) {
                        transform: rotate(-90deg) translateY(150%);
                        right: 23px;
                        @include respond-to-min($tabletSize) {
                            right: 15px;
                        }
                        @include respond-to-min($desktopSize) {
                            right: 0;
                        }
                    }
                }
            //}
        }

        .owl-dots {
            margin-top: $gutter * 0.25;
            .owl-dot {
                &.active {
                    span {
                        background: $primary-color;
                    }
                }
            }
        }
    }
}
