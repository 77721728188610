//https://zellwk.com/blog/media-query-units/

$mobileBasic:           320px;
$mobileIphoneSE:        375px;
$phonePortrait:         480px;
$maxMobileContainerSize:576px;
$phoneLandscape:        640px;
$tabletSize:            768px;
$desktopSize:           992px;
$laptopSize:            1200px;
$defaultDesktopSize:    1400px;
$normalDesktopSize:     1600px;
$largeDesktopSize:      1750px;
$fullHDSize:            1920px;
$maxContainerSize:      1632px;
$learnContainerSize:    1360px;

@mixin respond-to-min($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin respond-to-max($width) {
    @media only screen and (max-width: $width - 1px) {
        @content;
    }
}

@mixin respond-to-min-max($min-width, $max-width) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width - 1px) {
        @content;
    }
}

@mixin respond-to-min-orientation($width, $orientation) {
    @media only screen and (min-width: $width) and (orientation: $orientation) {
        @content;
    }
}

@mixin respond-to-max-orientation($width, $orientation) {
    @media only screen and (max-width: $width - 1px) and (orientation: $orientation) {
        @content;
    }
}

@mixin respond-to-min-max-orientation($min-width, $max-width, $orientation) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width - 1px) and (orientation: $orientation) {
        @content;
    }
}

@mixin respond-to-ipad($orientation, $retina) {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $retina) {
        @content
    }
}

@mixin only-touch-device {
    @media (hover: none) {
        @content;
    }
}
