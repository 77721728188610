@mixin news-item {
    .entries {
        @include respond-to-min($desktopSize) {
            margin-bottom: $gutter * 3;
            margin-top: 4rem;
        }



        @include respond-to-max($desktopSize) {
            margin-bottom: $gutter * 2;
            padding: $gutter * 0.5;

            .owl-carousel.owl-drag {
                .owl-item {

                }
            }
        }
        @include respond-to-max($tabletSize) {
            padding: $gutter * 0.5 $gutter;
        }
        .row {
            @include respond-to-min($desktopSize) {
                --bs-gutter-x: #{$gutter};
            }
            .entry {
                h2 {
                    margin-top: $gutter * 0.5;
                }
            }
        }
    }

    .entry {
        h2 {
            font-size: 2.4rem;
            font-weight: 600;
            margin-bottom: 0;
            a {
                color: $black;
            }
        }
        &-date{
            font-size: $font-size--base;
            color: $primary-color;
        }

        .short-description {
            font-weight: 500;
            font-size: $font-size--medium;
            a {
                padding: 0 $gutter * 0.125;
                font-weight: 600;
                &::after {
                    content: " > ";
                }
            }
        }

        .feature-image {
            height: 0;
            padding-top: 56.25%;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;
                transform: scale(1);

                &.lazy {
                    width: auto;
                    height: auto;
                    left: calc(50% - 150px);
                }
            }

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}
